.main-card {
  width: 100%;
  font-style: normal;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  width: 15px;
  border-radius: 5px;
}
.privacy-footer-div {
  width: 100%;
  padding-bottom: 7px;
}
.pad-top {
  padding-top: 125px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .desktop-view {
    display: none !important;
    overflow: visible;
  }
  .menu {
    padding: 50px 16px 0px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .desktop-view {
    display: none !important;
  }
  .menu {
    padding: 50px 16px 0px;
  }
}
