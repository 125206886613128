.cookies-div {
  border: 1px solid #e9e2d9;
  background: #ffffff;
  position: absolute;
  width: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(168, 148, 121, 0.2);
  border-radius: 16px;
}

@media only screen and (min-width: 1500px) {
  footer {
    padding-top: 1%;
  }

  .cookies-div {
    transform: translate(-50%, -30%) !important;
  }
}
.cookies-desktop-logo {
  padding: 40px 35px 29px 25px;
}
.bilista-logo-div {
  padding: 50px 0 20px 0;
}
.bilista-logo-div img {
  width: 28vw;
}
@media only screen and (max-width: 992px) {
  footer {
    position: relative;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
  .large-device {
    display: none !important;
  }
  .cookies-button {
    font-size: 15px !important;
    border: 2px solid #aa21ff;
  }
  /* .popup-content {
    width: 90% !important;
    height: 50% !important;
    padding: 28px 30px 3px 30px !important;
     margin-bottom: 10px !important; 
  } */
  .mb-20 {
    margin-bottom: 20px !important;
  }
  .mob-popup-header {
    font-size: 17px;
  }
  .mob-popup-content {
    font-size: 13px;
    line-height: 19px;
    margin: 0;
  }
}
.cookies-text {
  padding: 13px 86px 7px 0px;
  font-size: 13px;
  font-weight: 500;
}
.more-info {
  color: #aa21ff;
  cursor: pointer;
  text-decoration: underline;
}
.cookies-text b {
  font-size: 14px;
  font-weight: 700;
}
.cookies-accept-div {
  padding: 50px 20px 0 1px;
}
.cookies-close-div {
  padding: 50px 10px 0 20px;
}
.cookies-close-div-2 {
  padding: 1px 10px 0 20px;
}
.cookies-accept-div-2 {
  padding: 1px 10px 0 20px;
}
.cookies-button {
  align-items: center;
  padding: 0px 2px;
  gap: 12px;
  width: 133px;
  height: 40px;
  border: 2px solid #aa21ff !important;
  outline: none;
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
}
.w-200 {
  width: 200px !important;
}
.cookies-accept {
  background: #aa21ff;
  color: white;
}
.cookies-close {
  color: #aa21ff;
  background: white;
}
.tick-button-icon {
  padding-right: 9px;
}
.close-button-icon {
  padding-right: 20px;
}
.MuiTab-textColorSecondary.Mui-selected {
  color: #090c33 !important;
  font-weight: bold;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #aa21ff !important;
}

@media only screen and (min-width: 992px) {
  .small-device {
    display: none !important;
  }
}
.footer-background-img {
  background-repeat: no-repeat;
  background-size: cover;
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 40%;
  overflow: auto;
  height: 90%;
  color: black;
  box-shadow: 0px 4px 10px rgba(168, 148, 121, 0.2);
  border-radius: 16px 8px 8px 16px;
}
.popup-text {
  color: black;
  font-weight: 500;
  font-size: 15px;
}
.popup-text b {
  font-size: 17px;
  font-weight: 700;
}
.p-25 {
  padding: 25px;
}
.pb-20 {
  padding-bottom: 20px;
}
