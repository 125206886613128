@media only screen and (max-width: 992px) {
  .header-desktop-view {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .mobile-view {
    display: none !important;
  }
}
.position-absolute {
  position: absolute;
}
.logo-div {
  width: 100%;
  padding: 13px 0 16px 3%;
  position: sticky;
}
.logo-div img {
  width: 9vw;
  height: auto;
  cursor: pointer;
}
