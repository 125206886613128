.menu-title {
  color: #000000;
  font-family: RobotoRegular;
  font-weight: 500;
  font-size: 13px;
  padding: 5px;
  padding-left: 14px;
}
.menu-title:hover {
  font-family: RobotoBold !important;
  font-size: 14px;
}
li {
  padding-top: 10px;
}
.cookies-privacy {
  position: fixed;
  bottom: 100px;
  width: 100%;
  left: 0;
}
.desktop-view {
  overflow-x: hidden;
}
.mail-lab {
  padding-left: 5px;
  padding-right: 0px;
  font-family: RobotoRegular;
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  color: #aa21ff;
  text-decoration-line: underline;
}
.pad {
  padding-top: 13px;
  padding-bottom: 13px;
}
.menu-active {
  font-family: RobotoBold;
  font-size: 14px;
  line-height: 120%;
  padding-left: 10px;
  width: 280px;
}
.mar-top {
  margin-top: 7px !important;
}
.active-border {
  width: 0px;
  height: 34px;
  border: 2px solid #aa21ff;
  background: #aa21ff;
  border-radius: 2px;
}

.Menu-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 300px;
  left: 32px;
  top: 166px;
  margin-top: 24px;
}

.sub-card {
  position: fixed;
  width: 300px;
  max-height: 65%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 32px;
  margin-top: 110px;
  margin-bottom: 122px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.sub-card::-webkit-scrollbar {
  width: 6px !important;
}
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .desktop-view {
    display: none !important;
  }
  .policy-header {
    /*  position: Fixed; */
    width: 100%;
    background-color: none;
  }

  .mobile-view {
    overflow: hidden !important;
  }

  .sub-card {
    margin-left: 10%;
    position: Fixed !important;
    margin-top: 50px;
  }

  .menu-title {
    font-size: 22px;
    padding-left: 0%;
  }

  .Menu-card {
    padding: 0%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    width: 300px;
    height: 576px;
  }
  .drop {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    padding: 20px 20px 25px 20px;
    z-index: 1000;
  }
  .active-border-mob {
    width: 0px;
    border: 2px solid #aa21ff;
    border-radius: 2px;
    display: flex;
  }

  .menu-sub-title {
    font-family: RobotoMedium;
    font-size: 14px;
    line-height: 120%;
    width: 88%;
  }
  .menu-drop {
    padding: 6px 0px 0 15px;
    width: 88%;
  }
  .dropdown {
    min-height: 330px;
    width: 88%;
    max-height: 360px;
    z-index: 9;
    overflow-y: scroll;
    padding-left: 20px;
    border-radius: 16px;
    margin-left: 24px;
  }
  .menu-drop {
    font-family: RobotoBold;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .icon {
    padding: 0px 0px 0px 10px;
  }
  .drop-title {
    font-size: 17px;
    min-width: 285px !important;
    margin: 0;
  }
}
@media only screen and (max-width: 992px) {
  .larg-view {
    display: none !important;
  }
  .menu-drop {
    font-family: RobotoBold;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .drop-title {
    min-width: 285px !important;
    border-left: 3px solid #aa21ff;
    padding-left: 8px;
    border-radius: 2px;
  }
  .sub-card {
    margin-left: 10%;
    width: 88%;
    position: Fixed !important;
    margin-top: 50px;
    width: 80%;
  }

  .menu-title {
    font-size: 22px;
  }

  .Menu-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    width: 300px;
    height: 576px;
  }

  .icon {
    padding: 0px 0px 0px 10px;
  }
}
@media only screen and (min-height: 880px) {
  .sub-card {
    position: fixed;
    width: 300px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    margin-left: 32px;
    margin-top: 110px;
    margin-bottom: 122px;
    border-radius: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
.privacy-footer-div {
  width: 100%;
}
.content-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  width: 100% !important;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  position: relative;
  padding: 0px 20px 20px 20px;
}

.title {
  padding-bottom: 15px;
  font-family: RobotoMedium;
  font-size: 25px;
  line-height: 33px;
  color: #000000;
  flex: none;
  width: 320px;
}
.data-card {
  margin-top: 15px;
  overflow: auto;
  max-height: 63vh;
  position: relative;
}
.data-card-height-80vh {
  max-height: 80vh !important;
}
.sub-title {
  padding-left: 0px;
  color: #000000;
  font-family: RobotoMedium;
  font-size: 18px;
  line-height: 120%;
}

.content {
  padding-bottom: 30px;
  width: 100%;
  font-family: RobotoRegular;
  font-size: 16px;
  line-height: 135%;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 1;
}
@media only screen and (max-width: 992px) {
  .content {
    font-size: 16px;
    padding-bottom: 0px !important;
  }

  .title {
    font-size: 25px;
  }

  .sub-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 991px) {
  .larg-view {
    display: none !important;
  }
  .content {
    font-size: 16px;
  }

  .policy-header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
  }
  .data-card {
    margin-top: 250px;
  }

  .title {
    font-size: 25px;
  }

  .sub-title {
    font-size: 18px;
  }
}
