@media only screen and (max-width: 992px) {
  .home-desktop-view {
    display: none !important;
  }
}

.home-header {
  position: absolute;
  width: 100%;
}

.pos-relative {
  position: relative;
}
.background-img {
  background-size: contain;
  background-repeat: no-repeat;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: #fef8f0;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (min-width: 993px) {
  .background-img {
    background-size: cover;
    background-position: bottom;
    height: 100%;
  }
}
.main-div {
  gap: 48px;
  width: 100%;
  margin-top: 9%;
}

.header-text-div {
  padding: 0px;
  gap: 10px;
  width: 100%;
  animation-name: text-animation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.header-text {
  width: 100%;
  font-family: RobotoBold;
  font-style: normal;
  font-size: 3rem;
  font-size: 50px;
  line-height: 92%;
  color: #090c33;
  margin-left: 25%;
  padding: 40px 10px 0 10px;
}
.content-text-div {
  width: 59%;
  margin-left: 26%;
  animation-name: text-animation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
@keyframes text-animation {
  0% {
    transform: translate(0, 50px);
  }
  50% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
.download-row-div {
  margin-left: 28%;
}
.content-text {
  width: 100%;
  font-family: RobotoRegular;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #090c33;
  margin-bottom: 10px !important;
  padding: 0 0 0 8px;
}
.download-main-div {
  animation-name: download-animation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes download-animation {
  0% {
    transform: translate(0, 100px);
  }
  50% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

.download-text-div {
  padding: 0 43px;
  gap: 4px;
  width: 100%;
  height: 34px;
}
.download-text {
  width: 100%;
  font-family: RobotoRegular;
  font-size: 1.275rem;
  color: #090c33;
  margin-left: 25%;
}
.download-btn-row {
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 50px;
  filter: drop-shadow(0px 4px 10px rgba(9, 12, 51, 0.25));
}
.download-btn {
  width: 14vw;
  height: 54px;
  background: #090c33;
  border-radius: 16px;
  border: none;
  font-size: 0.8rem;
  color: #fef8f0;
  font-family: RobotoRegular;
  padding: 12px 6px 12px 6px;
}
.apple-button-icon {
  padding-right: 9px;
  width: 25px;
  margin-top: -3px;
}
.playstore-button-icon {
  padding-right: 5px;
  width: 25px;
  margin-top: -3px;
}
@media only screen and (min-width: 1600px) {
  .main-div {
    margin-top: 13%;
  }
  .download-row-div {
    margin-left: 27%;
  }
  .download-btn {
    width: 16vw;
    height: 64px;
    font-size: 18px;
  }
  .download-main-div {
    margin-top: 10%;
  }
  .phone-image-div {
    padding-top: 4% !important;
    margin-left: 6% !important;
  }
  .phone-img1 {
    width: 370px !important;
  }
  .phone-img2 {
    width: 381px !important;
    margin-left: 181px !important;
  }
  .phone-img3 {
    width: 417px !important;
    margin-left: 323px !important;
  }
}
@media only screen and (min-width: 2560px) {
  .main-div {
    margin-top: 20%;
  }
  .screen-height {
    height: 84vh;
  }
  .header-text {
    font-size: 69px;
  }
  .content-text-div {
    width: 400px !important;
    margin-left: 26% !important;
  }
  .content-text {
    font-size: 20px;
    line-height: 25px;
  }

  .download-row-div {
    margin-left: 27%;
  }
  .download-btn {
    width: 12vw;
    height: 64px;
    font-size: 20px;
  }
  .download-main-div {
    margin-top: 10%;
  }
  .phone-image-div {
    padding-top: 7% !important;
    margin-left: 0 !important;
  }
  .phone-img1 {
    width: 550px !important;
  }
  .phone-img2 {
    width: 600px !important;
    margin-left: 254px !important;
  }
  .phone-img3 {
    width: 635px !important;
    margin-left: 493px !important;
    margin-top: 66px !important;
  }
}
@media screen and (min-width: 993px) and (max-width: 1295px) {
  .content-text-div {
    width: 64%;
    margin-left: 26%;
  }
  .header-text {
    font-size: 2.5rem;
  }
  .download-btn {
    width: 15vw;
    font-weight: 100;
    font-size: 0.75rem;
  }
  .button-icon {
    padding-right: 2px;
    width: 2vw;
  }
}
@media screen and (min-width: 1296px) {
  .content-text-div {
    width: 336px;
    margin-left: 26%;
  }
  .footer-content-gap {
    padding-bottom: 25px;
  }
}
.download-btn-div {
  padding: 7px 20px 0 0;
}
@media only screen and (min-height: 790px) and (max-height: 1339px) {
  .phone-image-div {
    padding-top: 4%;
    margin-top: 3%;
  }
  .screen-height {
    height: 84vh;
  }
  .main-div {
    margin-top: 117px;
  }
  .download-text-div {
    margin-top: 0;
  }
  .header-text {
    line-height: 90%;
    margin-left: 25%;
    padding: 40px 10px 0 10px;
  }
  .content-text {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }
}
.phone-image-div {
  padding-top: 4%;
  animation: phone-animate 2s alternate;
  margin-left: 3%;
}
.phone-img1 {
  width: 304px;
  position: absolute;
  top: 0;
  z-index: 30;
  animation: phone-animate 2s alternate;
}
.phone-img2 {
  width: 308px;
  z-index: 20;
  position: absolute;
  top: 0;
  margin-left: 150px;
  animation: phone-animate 2s alternate;
}
.phone-img3 {
  width: 320px;
  z-index: 10;
  top: 0;
  position: absolute;
  margin-left: 274px;
  margin-top: 42px;
  animation: phone-animate 2s alternate;
}
@keyframes phone-animate {
  0% {
    transform: rotate(60deg) translate(40%, 0%) rotate(-45deg) scale(0);
    opacity: 1;
  }
  100% {
    transform: rotate(-0) translate(0) rotate(0) scale(1);
    opacity: 1;
  }
}

/* For Mobile View */

@media only screen and (min-width: 993px) {
  .mobile-view-display {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .mob-image-div {
    padding-top: 8%;
    animation: phone-animate 2s alternate;
  }
  .mobile-img {
    width: 91vw;
    height: auto;
  }
  .mob-download-text-div {
    font-family: RobotoMedium;
    font-size: 5.5vw;
    line-height: 26px;
    text-align: center;
    margin-bottom: 9px;
  }
  .download-btn {
    width: 74vw;
    height: 54px;
    padding: 7px;
    font-size: 20px;
    box-shadow: 0px 7px 5px #dfdfdf;
  }
  .apple-button-icon {
    height: auto;
    width: 33px;
    padding-bottom: 4px;
    padding-right: 16px;
  }
  .playstore-button-icon {
    height: auto;
    width: 36px;
    padding-bottom: 4px;
    padding-right: 14px;
  }
  .header-text {
    margin-left: 2%;
    font-size: 2.5rem;
    line-height: 50px;
    margin-bottom: 5px;
  }
  .content-text {
    font-size: 17px;
    line-height: 22px;
    margin-left: 3%;
    margin-right: 3%;
  }
  .cookies {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
