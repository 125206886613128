body {
  overflow-x: hidden !important;
}
html {
  margin: 0;
  font-family: RobotoRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fef8f0;
  height: calc(100vh - calc(100vh - 100%));
}

/* .App {
  text-align: center;
  -ms-overflow-style: hidden;
}

::-webkit-scrollbar {
  display: none;
}
 */

@media only screen and (max-width: 992px) {
  .home-desktop-view {
    display: none !important;
  }
}

.home-header {
  position: absolute;
  width: 100%;
}

.pos-relative {
  position: relative;
}
.background-img {
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: fadeInAnimation ease 3s;
          animation: fadeInAnimation ease 3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background-color: #fef8f0;
}
@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (min-width: 993px) {
  .background-img {
    background-size: cover;
    background-position: bottom;
    height: 100%;
  }
}
.main-div {
  grid-gap: 48px;
  gap: 48px;
  width: 100%;
  margin-top: 9%;
}

.header-text-div {
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  -webkit-animation-name: text-animation;
          animation-name: text-animation;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
.header-text {
  width: 100%;
  font-family: RobotoBold;
  font-style: normal;
  font-size: 3rem;
  font-size: 50px;
  line-height: 92%;
  color: #090c33;
  margin-left: 25%;
  padding: 40px 10px 0 10px;
}
.content-text-div {
  width: 59%;
  margin-left: 26%;
  -webkit-animation-name: text-animation;
          animation-name: text-animation;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
@-webkit-keyframes text-animation {
  0% {
    -webkit-transform: translate(0, 50px);
            transform: translate(0, 50px);
  }
  50% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
}
@keyframes text-animation {
  0% {
    -webkit-transform: translate(0, 50px);
            transform: translate(0, 50px);
  }
  50% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
}
.download-row-div {
  margin-left: 28%;
}
.content-text {
  width: 100%;
  font-family: RobotoRegular;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #090c33;
  margin-bottom: 10px !important;
  padding: 0 0 0 8px;
}
.download-main-div {
  -webkit-animation-name: download-animation;
          animation-name: download-animation;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes download-animation {
  0% {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px);
  }
  50% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
}

@keyframes download-animation {
  0% {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px);
  }
  50% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
}

.download-text-div {
  padding: 0 43px;
  grid-gap: 4px;
  gap: 4px;
  width: 100%;
  height: 34px;
}
.download-text {
  width: 100%;
  font-family: RobotoRegular;
  font-size: 1.275rem;
  color: #090c33;
  margin-left: 25%;
}
.download-btn-row {
  padding: 0px;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
  height: 50px;
  -webkit-filter: drop-shadow(0px 4px 10px rgba(9, 12, 51, 0.25));
          filter: drop-shadow(0px 4px 10px rgba(9, 12, 51, 0.25));
}
.download-btn {
  width: 14vw;
  height: 54px;
  background: #090c33;
  border-radius: 16px;
  border: none;
  font-size: 0.8rem;
  color: #fef8f0;
  font-family: RobotoRegular;
  padding: 12px 6px 12px 6px;
}
.apple-button-icon {
  padding-right: 9px;
  width: 25px;
  margin-top: -3px;
}
.playstore-button-icon {
  padding-right: 5px;
  width: 25px;
  margin-top: -3px;
}
@media only screen and (min-width: 1600px) {
  .main-div {
    margin-top: 13%;
  }
  .download-row-div {
    margin-left: 27%;
  }
  .download-btn {
    width: 16vw;
    height: 64px;
    font-size: 18px;
  }
  .download-main-div {
    margin-top: 10%;
  }
  .phone-image-div {
    padding-top: 4% !important;
    margin-left: 6% !important;
  }
  .phone-img1 {
    width: 370px !important;
  }
  .phone-img2 {
    width: 381px !important;
    margin-left: 181px !important;
  }
  .phone-img3 {
    width: 417px !important;
    margin-left: 323px !important;
  }
}
@media only screen and (min-width: 2560px) {
  .main-div {
    margin-top: 20%;
  }
  .screen-height {
    height: 84vh;
  }
  .header-text {
    font-size: 69px;
  }
  .content-text-div {
    width: 400px !important;
    margin-left: 26% !important;
  }
  .content-text {
    font-size: 20px;
    line-height: 25px;
  }

  .download-row-div {
    margin-left: 27%;
  }
  .download-btn {
    width: 12vw;
    height: 64px;
    font-size: 20px;
  }
  .download-main-div {
    margin-top: 10%;
  }
  .phone-image-div {
    padding-top: 7% !important;
    margin-left: 0 !important;
  }
  .phone-img1 {
    width: 550px !important;
  }
  .phone-img2 {
    width: 600px !important;
    margin-left: 254px !important;
  }
  .phone-img3 {
    width: 635px !important;
    margin-left: 493px !important;
    margin-top: 66px !important;
  }
}
@media screen and (min-width: 993px) and (max-width: 1295px) {
  .content-text-div {
    width: 64%;
    margin-left: 26%;
  }
  .header-text {
    font-size: 2.5rem;
  }
  .download-btn {
    width: 15vw;
    font-weight: 100;
    font-size: 0.75rem;
  }
  .button-icon {
    padding-right: 2px;
    width: 2vw;
  }
}
@media screen and (min-width: 1296px) {
  .content-text-div {
    width: 336px;
    margin-left: 26%;
  }
  .footer-content-gap {
    padding-bottom: 25px;
  }
}
.download-btn-div {
  padding: 7px 20px 0 0;
}
@media only screen and (min-height: 790px) and (max-height: 1339px) {
  .phone-image-div {
    padding-top: 4%;
    margin-top: 3%;
  }
  .screen-height {
    height: 84vh;
  }
  .main-div {
    margin-top: 117px;
  }
  .download-text-div {
    margin-top: 0;
  }
  .header-text {
    line-height: 90%;
    margin-left: 25%;
    padding: 40px 10px 0 10px;
  }
  .content-text {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }
}
.phone-image-div {
  padding-top: 4%;
  -webkit-animation: phone-animate 2s alternate;
          animation: phone-animate 2s alternate;
  margin-left: 3%;
}
.phone-img1 {
  width: 304px;
  position: absolute;
  top: 0;
  z-index: 30;
  -webkit-animation: phone-animate 2s alternate;
          animation: phone-animate 2s alternate;
}
.phone-img2 {
  width: 308px;
  z-index: 20;
  position: absolute;
  top: 0;
  margin-left: 150px;
  -webkit-animation: phone-animate 2s alternate;
          animation: phone-animate 2s alternate;
}
.phone-img3 {
  width: 320px;
  z-index: 10;
  top: 0;
  position: absolute;
  margin-left: 274px;
  margin-top: 42px;
  -webkit-animation: phone-animate 2s alternate;
          animation: phone-animate 2s alternate;
}
@-webkit-keyframes phone-animate {
  0% {
    -webkit-transform: rotate(60deg) translate(40%, 0%) rotate(-45deg) scale(0);
            transform: rotate(60deg) translate(40%, 0%) rotate(-45deg) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-0) translate(0) rotate(0) scale(1);
            transform: rotate(-0) translate(0) rotate(0) scale(1);
    opacity: 1;
  }
}
@keyframes phone-animate {
  0% {
    -webkit-transform: rotate(60deg) translate(40%, 0%) rotate(-45deg) scale(0);
            transform: rotate(60deg) translate(40%, 0%) rotate(-45deg) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-0) translate(0) rotate(0) scale(1);
            transform: rotate(-0) translate(0) rotate(0) scale(1);
    opacity: 1;
  }
}

/* For Mobile View */

@media only screen and (min-width: 993px) {
  .mobile-view-display {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .mob-image-div {
    padding-top: 8%;
    -webkit-animation: phone-animate 2s alternate;
            animation: phone-animate 2s alternate;
  }
  .mobile-img {
    width: 91vw;
    height: auto;
  }
  .mob-download-text-div {
    font-family: RobotoMedium;
    font-size: 5.5vw;
    line-height: 26px;
    text-align: center;
    margin-bottom: 9px;
  }
  .download-btn {
    width: 74vw;
    height: 54px;
    padding: 7px;
    font-size: 20px;
    box-shadow: 0px 7px 5px #dfdfdf;
  }
  .apple-button-icon {
    height: auto;
    width: 33px;
    padding-bottom: 4px;
    padding-right: 16px;
  }
  .playstore-button-icon {
    height: auto;
    width: 36px;
    padding-bottom: 4px;
    padding-right: 14px;
  }
  .header-text {
    margin-left: 2%;
    font-size: 2.5rem;
    line-height: 50px;
    margin-bottom: 5px;
  }
  .content-text {
    font-size: 17px;
    line-height: 22px;
    margin-left: 3%;
    margin-right: 3%;
  }
  .cookies {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .header-desktop-view {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .mobile-view {
    display: none !important;
  }
}
.position-absolute {
  position: absolute;
}
.logo-div {
  width: 100%;
  padding: 13px 0 16px 3%;
  position: -webkit-sticky;
  position: sticky;
}
.logo-div img {
  width: 9vw;
  height: auto;
  cursor: pointer;
}

.cookies-div {
  border: 1px solid #e9e2d9;
  background: #ffffff;
  position: absolute;
  width: 75%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(168, 148, 121, 0.2);
  border-radius: 16px;
}

@media only screen and (min-width: 1500px) {
  footer {
    padding-top: 1%;
  }

  .cookies-div {
    -webkit-transform: translate(-50%, -30%) !important;
            transform: translate(-50%, -30%) !important;
  }
}
.cookies-desktop-logo {
  padding: 40px 35px 29px 25px;
}
.bilista-logo-div {
  padding: 50px 0 20px 0;
}
.bilista-logo-div img {
  width: 28vw;
}
@media only screen and (max-width: 992px) {
  footer {
    position: relative;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
  .large-device {
    display: none !important;
  }
  .cookies-button {
    font-size: 15px !important;
    border: 2px solid #aa21ff;
  }
  /* .popup-content {
    width: 90% !important;
    height: 50% !important;
    padding: 28px 30px 3px 30px !important;
     margin-bottom: 10px !important; 
  } */
  .mb-20 {
    margin-bottom: 20px !important;
  }
  .mob-popup-header {
    font-size: 17px;
  }
  .mob-popup-content {
    font-size: 13px;
    line-height: 19px;
    margin: 0;
  }
}
.cookies-text {
  padding: 13px 86px 7px 0px;
  font-size: 13px;
  font-weight: 500;
}
.more-info {
  color: #aa21ff;
  cursor: pointer;
  text-decoration: underline;
}
.cookies-text b {
  font-size: 14px;
  font-weight: 700;
}
.cookies-accept-div {
  padding: 50px 20px 0 1px;
}
.cookies-close-div {
  padding: 50px 10px 0 20px;
}
.cookies-close-div-2 {
  padding: 1px 10px 0 20px;
}
.cookies-accept-div-2 {
  padding: 1px 10px 0 20px;
}
.cookies-button {
  align-items: center;
  padding: 0px 2px;
  grid-gap: 12px;
  gap: 12px;
  width: 133px;
  height: 40px;
  border: 2px solid #aa21ff !important;
  outline: none;
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
}
.w-200 {
  width: 200px !important;
}
.cookies-accept {
  background: #aa21ff;
  color: white;
}
.cookies-close {
  color: #aa21ff;
  background: white;
}
.tick-button-icon {
  padding-right: 9px;
}
.close-button-icon {
  padding-right: 20px;
}
.MuiTab-textColorSecondary.Mui-selected {
  color: #090c33 !important;
  font-weight: bold;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #aa21ff !important;
}

@media only screen and (min-width: 992px) {
  .small-device {
    display: none !important;
  }
}
.footer-background-img {
  background-repeat: no-repeat;
  background-size: cover;
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 40%;
  overflow: auto;
  height: 90%;
  color: black;
  box-shadow: 0px 4px 10px rgba(168, 148, 121, 0.2);
  border-radius: 16px 8px 8px 16px;
}
.popup-text {
  color: black;
  font-weight: 500;
  font-size: 15px;
}
.popup-text b {
  font-size: 17px;
  font-weight: 700;
}
.p-25 {
  padding: 25px;
}
.pb-20 {
  padding-bottom: 20px;
}

footer {
  width: 100%;
  font-style: normal;
  align-self: stretch;
  left: 0;
  bottom: 0;
  padding-bottom: 9px;
  background-color: #fef8f0;
}
.email-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  color: #090c33 !important;
  font-family: RobotoRegular;
}

.contact-number {
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: RobotoRegular;
  color: #090c33 !important;
  text-decoration: none;
}
@media only screen and (min-width: 1500px) {
  footer {
    padding-top: 1%;
  }
}
.bilista-desktop-logo {
  width: 6%;
  height: auto;
}
.bilista-logo-div {
  padding: 50px 0 20px 0;
}
.bilista-logo-div img {
  width: 28vw;
}
@media only screen and (max-width: 992px) {
  footer {
    position: relative;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
  .large-device {
    display: none !important;
  }
  .bilista-logo-div {
    padding: 50px 0 33px 0;
  }
  .contact-label {
    margin-bottom: 0;
    text-align: center;
    font-size: 15px !important;
    font-weight: 700;
  }
  .contact-label b {
    font-size: 18px;
  }

  .contact-label span {
    font-size: 14px;
  }
  .nav-buttons {
    font-size: 15px !important;
    color: #090c33 !important;
  }
}
.tab-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  grid-gap: 64px;
  gap: 64px;
  width: 1588px;
  height: 42px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.contact-email-div {
  padding: 10px 9% 0 0;
}
.contact-number-div {
  padding: 10px 0 0 9%;
}
.contact-label {
  width: 100%;
  height: 22px;
  font-family: RobotoRegular;
  font-size: 14px;
  line-height: 17px;
  color: #090c33;
}
.contact-label b {
  font-size: 15px;
}
.nav-buttons {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  font-family: RobotoBold;
  font-size: 16px;
  line-height: 22px;
  background: none;
  padding: 0;
}
.selected-page {
  border-bottom: 2px solid #aa21ff;
}
.MuiTab-textColorSecondary.Mui-selected {
  color: #090c33 !important;
  font-weight: 700;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #aa21ff !important;
}

@media only screen and (min-width: 992px) {
  .small-device {
    display: none !important;
  }
}
.footer-background-img {
  background-repeat: no-repeat;
  background-size: cover;
}

.main-card {
  width: 100%;
  font-style: normal;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  width: 15px;
  border-radius: 5px;
}
.privacy-footer-div {
  width: 100%;
  padding-bottom: 7px;
}
.pad-top {
  padding-top: 125px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .desktop-view {
    display: none !important;
    overflow: visible;
  }
  .menu {
    padding: 50px 16px 0px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .desktop-view {
    display: none !important;
  }
  .menu {
    padding: 50px 16px 0px;
  }
}

.menu-title {
  color: #000000;
  font-family: RobotoRegular;
  font-weight: 500;
  font-size: 13px;
  padding: 5px;
  padding-left: 14px;
}
.menu-title:hover {
  font-family: RobotoBold !important;
  font-size: 14px;
}
li {
  padding-top: 10px;
}
.cookies-privacy {
  position: fixed;
  bottom: 100px;
  width: 100%;
  left: 0;
}
.desktop-view {
  overflow-x: hidden;
}
.mail-lab {
  padding-left: 5px;
  padding-right: 0px;
  font-family: RobotoRegular;
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  color: #aa21ff;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.pad {
  padding-top: 13px;
  padding-bottom: 13px;
}
.menu-active {
  font-family: RobotoBold;
  font-size: 14px;
  line-height: 120%;
  padding-left: 10px;
  width: 280px;
}
.mar-top {
  margin-top: 7px !important;
}
.active-border {
  width: 0px;
  height: 34px;
  border: 2px solid #aa21ff;
  background: #aa21ff;
  border-radius: 2px;
}

.Menu-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
  width: 300px;
  left: 32px;
  top: 166px;
  margin-top: 24px;
}

.sub-card {
  position: fixed;
  width: 300px;
  max-height: 65%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 32px;
  margin-top: 110px;
  margin-bottom: 122px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.sub-card::-webkit-scrollbar {
  width: 6px !important;
}
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .desktop-view {
    display: none !important;
  }
  .policy-header {
    /*  position: Fixed; */
    width: 100%;
    background-color: none;
  }

  .mobile-view {
    overflow: hidden !important;
  }

  .sub-card {
    margin-left: 10%;
    position: Fixed !important;
    margin-top: 50px;
  }

  .menu-title {
    font-size: 22px;
    padding-left: 0%;
  }

  .Menu-card {
    padding: 0%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    width: 300px;
    height: 576px;
  }
  .drop {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    padding: 20px 20px 25px 20px;
    z-index: 1000;
  }
  .active-border-mob {
    width: 0px;
    border: 2px solid #aa21ff;
    border-radius: 2px;
    display: flex;
  }

  .menu-sub-title {
    font-family: RobotoMedium;
    font-size: 14px;
    line-height: 120%;
    width: 88%;
  }
  .menu-drop {
    padding: 6px 0px 0 15px;
    width: 88%;
  }
  .dropdown {
    min-height: 330px;
    width: 88%;
    max-height: 360px;
    z-index: 9;
    overflow-y: scroll;
    padding-left: 20px;
    border-radius: 16px;
    margin-left: 24px;
  }
  .menu-drop {
    font-family: RobotoBold;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .icon {
    padding: 0px 0px 0px 10px;
  }
  .drop-title {
    font-size: 17px;
    min-width: 285px !important;
    margin: 0;
  }
}
@media only screen and (max-width: 992px) {
  .larg-view {
    display: none !important;
  }
  .menu-drop {
    font-family: RobotoBold;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .drop-title {
    min-width: 285px !important;
    border-left: 3px solid #aa21ff;
    padding-left: 8px;
    border-radius: 2px;
  }
  .sub-card {
    margin-left: 10%;
    width: 88%;
    position: Fixed !important;
    margin-top: 50px;
    width: 80%;
  }

  .menu-title {
    font-size: 22px;
  }

  .Menu-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    width: 300px;
    height: 576px;
  }

  .icon {
    padding: 0px 0px 0px 10px;
  }
}
@media only screen and (min-height: 880px) {
  .sub-card {
    position: fixed;
    width: 300px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    margin-left: 32px;
    margin-top: 110px;
    margin-bottom: 122px;
    border-radius: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
.privacy-footer-div {
  width: 100%;
}
.content-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
  width: 100% !important;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  position: relative;
  padding: 0px 20px 20px 20px;
}

.title {
  padding-bottom: 15px;
  font-family: RobotoMedium;
  font-size: 25px;
  line-height: 33px;
  color: #000000;
  flex: none;
  width: 320px;
}
.data-card {
  margin-top: 15px;
  overflow: auto;
  max-height: 63vh;
  position: relative;
}
.data-card-height-80vh {
  max-height: 80vh !important;
}
.sub-title {
  padding-left: 0px;
  color: #000000;
  font-family: RobotoMedium;
  font-size: 18px;
  line-height: 120%;
}

.content {
  padding-bottom: 30px;
  width: 100%;
  font-family: RobotoRegular;
  font-size: 16px;
  line-height: 135%;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 1;
}
@media only screen and (max-width: 992px) {
  .content {
    font-size: 16px;
    padding-bottom: 0px !important;
  }

  .title {
    font-size: 25px;
  }

  .sub-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 991px) {
  .larg-view {
    display: none !important;
  }
  .content {
    font-size: 16px;
  }

  .policy-header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
  }
  .data-card {
    margin-top: 250px;
  }

  .title {
    font-size: 25px;
  }

  .sub-title {
    font-size: 18px;
  }
}

