body {
  overflow-x: hidden !important;
}
html {
  margin: 0;
  font-family: RobotoRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fef8f0;
  height: calc(100vh - calc(100vh - 100%));
}
