footer {
  width: 100%;
  font-style: normal;
  align-self: stretch;
  left: 0;
  bottom: 0;
  padding-bottom: 9px;
  background-color: #fef8f0;
}
.email-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  color: #090c33 !important;
  font-family: RobotoRegular;
}

.contact-number {
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: RobotoRegular;
  color: #090c33 !important;
  text-decoration: none;
}
@media only screen and (min-width: 1500px) {
  footer {
    padding-top: 1%;
  }
}
.bilista-desktop-logo {
  width: 6%;
  height: auto;
}
.bilista-logo-div {
  padding: 50px 0 20px 0;
}
.bilista-logo-div img {
  width: 28vw;
}
@media only screen and (max-width: 992px) {
  footer {
    position: relative;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
  .large-device {
    display: none !important;
  }
  .bilista-logo-div {
    padding: 50px 0 33px 0;
  }
  .contact-label {
    margin-bottom: 0;
    text-align: center;
    font-size: 15px !important;
    font-weight: 700;
  }
  .contact-label b {
    font-size: 18px;
  }

  .contact-label span {
    font-size: 14px;
  }
  .nav-buttons {
    font-size: 15px !important;
    color: #090c33 !important;
  }
}
.tab-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 64px;
  width: 1588px;
  height: 42px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.contact-email-div {
  padding: 10px 9% 0 0;
}
.contact-number-div {
  padding: 10px 0 0 9%;
}
.contact-label {
  width: 100%;
  height: 22px;
  font-family: RobotoRegular;
  font-size: 14px;
  line-height: 17px;
  color: #090c33;
}
.contact-label b {
  font-size: 15px;
}
.nav-buttons {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  font-family: RobotoBold;
  font-size: 16px;
  line-height: 22px;
  background: none;
  padding: 0;
}
.selected-page {
  border-bottom: 2px solid #aa21ff;
}
.MuiTab-textColorSecondary.Mui-selected {
  color: #090c33 !important;
  font-weight: 700;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #aa21ff !important;
}

@media only screen and (min-width: 992px) {
  .small-device {
    display: none !important;
  }
}
.footer-background-img {
  background-repeat: no-repeat;
  background-size: cover;
}
